<template>
  <v-row>
    <v-dialog class="dialog-1"
      v-model="dialog"
      persistent
      max-width="100%">
      <v-icon @click="close()" size="25" class="white--text">mdi-close</v-icon>
      <v-card>
      <cropper class="cropper" :src="src" ref="cropper"
      :stencil-props="{ aspectRatio: 1}"
      @change="change"></cropper>
      <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="crop()">
            ذخیره
          </v-btn>
          <v-spacer></v-spacer>
        <v-icon color="primary" @click="rotate(90)">mdi-orbit-variant</v-icon>
        <v-spacer></v-spacer>
        <v-icon color="primary" @click="flip(false,true)" >mdi-flip-vertical</v-icon>
        <v-spacer></v-spacer>
        <v-icon color="primary" @click="flip(true,false)">mdi-flip-horizontal</v-icon>
 
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
  export default {
    props: ['url'],
    data: () => ({
      dialog: true,
      src: null
    }),
    components: {
      Cropper,
    },
    mounted () {
      this.src = this.$props.url
    },
    methods: {
    flip(x,y) { 
		this.$refs.cropper.flip(x,y);
	},
	rotate(angle) {
		this.$refs.cropper.rotate(angle);
  },
    crop() {
	const { coordinates, canvas, } = this.$refs.cropper.getResult()
	this.coordinates = coordinates
    const image = canvas.toDataURL()
    this.$emit('image',{image: image, open: false})
  },
  close () {
    this.dialog = !this.dialog
    this.$emit('close', false)
    this.src = null
  }
}
}
</script>
<style lang="scss">
.cropper{
  height: 300px;
}
</style>