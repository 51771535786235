<template>
<v-container>
<div>
  <navbar title="پروفایل"/>
    <v-main class="pt-15 pb-15">
    <v-layout row wrap class="justify-center">
      <v-flex sm12 md12>
      <v-col cols="12" sm="10" md="12" class="">
      <v-card ref="form" class="mx-auto card ">
          <div class="accent image-container">
            <v-avatar size="150">
            <v-img :src="user.image" lazy-src="user.image" max-height="230" max-width="100%" min-height="230"/>
            </v-avatar>
          </div>
          <div class="menu">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item  link>
              <v-icon size="19" class="mb-1">mdi-trash-can-outline</v-icon>
              <v-list-item-title @click="Out('avatar')" class="py-0">حذف تصویر</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </div>
            <div class="input-container">
              <input class="input-file" id="my-file" type="file" hidden @change="getImage">
              <label tabindex="0" for="my-file" class="input-file-trigger primary p-10">
              <v-icon dark>mdi-camera</v-icon>
              </label>
          </div>
          <div>
            <error/>
            <success/>
          </div>
          <v-form @submit.prevent="submit()" ref="editProfile">
          <v-card-text>
            <v-text-field maxlength="21" v-model="user.nick_name" label="نام کاربری*" :rules="[ rules.required, rules.count ]" type="text"></v-text-field>
            <v-text-field maxlength="21" v-model="user.first_name" label="نام" type="text" :rules="[ rules.required, rules.count ]" required></v-text-field>
            <v-text-field maxlength="21" v-model="user.last_name" type="text" label="نام خانوادگی" :rules="[ rules.required, rules.count ]" required></v-text-field>
            <v-text-field maxlength="21" disabled v-model="user.mobile" type="tel" label="شماره همراه*" :rules="[ rules.required, rules.phone ]" required></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary button" dark type="submit">
            <span>ذخیره اطلاعات</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      </v-col>
      </v-flex>
    </v-layout>
    <cropper v-if="src" :url= 'src' @close= 'close' @image = 'image'/>
    <loading v-if="loading"/>
    <div>
      <action/>
    </div>
    </v-main>
  </div>
</v-container>
</template>
<style lang="scss" scoped>
.is-loading {
  color:transparent;
}
.loading{
  font-size: 8px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}

.image-container{
  padding: 8px;
  display: flex;
  justify-content: center;
}
.menu{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    display: inline-flex;
  }
.v-list-item{
  min-height: 35px !important;
  .v-list-item__title{
    font-size: 13px !important;
  }
}
@media(min-width: 600px){
  .card-column{
    flex: 0 0 100%;
    max-width: 600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}

</style>
<script>
import error from '@/components/alerts/error.alert'
import success from '@/components/alerts/success.alert'
import cropper from '@/components/cropper/cropper'
import navbar from '@/components/navbar/appBar'
import { mapState } from 'vuex'
import { editProfile, getProfile } from './models/user'
import loading from '@/components/tools/loadingPage'
import {alertMessage} from '@/assets/messageAlert'
import { logOut } from '@/views/auth/models/auth'
import { update_avatar } from './models/user'
import router from '@/router'
import { viewbasket_products } from '@/views/shop/Basket/models/Basket'
import store from '@/store'
import { toEnglishDigits } from '@/models/changeType'
  export default {
    components: {
      error,
      success,
      cropper,
      navbar,
      loading,
      action: () => import ('../shop/home/components/navbar/actionBar'),
    },
    data: () => ({
      infoUser: {},
      src: '',
      rules: {
        required: null,
        count: value => value.length <= 20 || alertMessage.error['MaxCharacters'],
        phone: value => {
          const mobile = toEnglishDigits(value)
          return mobile.slice(0,2) == '09' || alertMessage.error['typePhone']}
      }
    }),
    created () {
      scrollTo(0,0)
      getProfile()
      const basket = localStorage.getItem('basket')
        if (basket) {
          const time = new Date()
          const now = time.getTime()
          const old_time_basket = parseInt(localStorage.getItem('time_basket'))
          if ((old_time_basket + 86400000) < now ) {
            localStorage.removeItem('basket')
            viewbasket_products()
          } else {
          store.dispatch('basket_local', JSON.parse(basket))
          }
        } else {
          viewbasket_products()
        }
    },
    computed: {
      ...mapState({
        user(state) {
          this.infoUser = state.user.user
          return this.infoUser
        },
        loading: state => state.loadingSubmit,
        alertSuccess: state => state.alert.success.show,
        alertError: state => state.alert.error.show
      })
    },
    methods: {
      check () {
        this.rules = {
          required: value => !!value || alertMessage.error['Required']
        }
      },
      submit () {
          const validate = this.$refs.editProfile.validate()
        if ( validate == true) {
          editProfile(this.infoUser)
        }
      },
       getImage(event) {
        const image = event.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = event => {
          this.src = event.target.result
        }
        document.getElementById('my-file').value = null
      },
      image (cropper) {
        this.infoUser.image = cropper.image
        this.src = null
      },
      close (close) {
        if (close == false) {
          this.src = null
        }
      },
      Out(type) {
            if (type == 'logOut') {
              logOut()
            }else {
              update_avatar()
            }
        },
  }
}
</script>
